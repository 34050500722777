import { useState } from "react";
import useGetUserInfo from "hooks/useGetUserInfo";
import logoutHandler from "utils/logoutHandler";
import { Drawer } from "antd";
import { NavLink } from "react-router-dom";
import { useSendLocationStampMutation } from "apis/services/visits";
import HandleErrors from "utils/handleErrors";
import Image from "../Image";
import Button from "../Button";
import Icon from "../Icon";
import Text from "../Text";
import { NavbarProps } from "./types";
import styles from "./styles.module.scss";

export default function Navbar({ containerStyle }: NavbarProps) {
  const [open, setOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const [sendLocationStamp, { isLoading: isSendLocationLoading }] =
    useSendLocationStampMutation();

  const { isClient, userInfo } = useGetUserInfo();

  const showError = (error: any) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        alert(
          "This site's Location permission is  denied. Please go to site settings and open location permission to be able to mark visits"
        );
        break;
      case error.POSITION_UNAVAILABLE:
        alert("Location information is unavailable.");
        break;
      case error.TIMEOUT:
        alert("The request to get user location timed out.");
        break;
      case error.UNKNOWN_ERROR:
        alert("An unknown error occurred.");
        break;
      default:
        break;
    }
  };

  const handleCheckIn = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        sendLocationStamp({
          lat: String(position.coords.latitude),
          lng: String(position.coords.longitude),
          is_check_in: true,
        })
          .unwrap()
          .catch((err) => HandleErrors(err)),
      showError
    );
  };

  const handleCheckOut = async () => {
    await navigator.geolocation.getCurrentPosition(
      (position) =>
        sendLocationStamp({
          lat: String(position.coords.latitude),
          lng: String(position.coords.longitude),
          is_check_out: true,
        })
          .unwrap()
          .catch((err) => HandleErrors(err)),
      showError
    );
  };

  return (
    <div className={`${styles.container ?? ""} ${containerStyle ?? ""}`}>
      <div className={styles.navbar}>
        {/* <Image src="" onClick={() => navigate("/")} role="button" /> */}
        {isClient && userInfo?.company?.logo ? (
          <div className={styles.logoContainer}>
            <Image src={userInfo?.company?.logo} className={styles.logo} />
          </div>
        ) : (
          userInfo?.company?.name
        )}
        <Button btnClassName={styles.menuBtn} onClick={toggleDrawer}>
          <Icon name={open ? "close" : "menu"} size={24} color="dark" />
        </Button>
      </div>
      <div className={styles.drawer}>
        <Drawer
          placement="right"
          open={open}
          getContainer={false}
          closeIcon={false}
          onClose={() => setOpen(false)}
        >
          <div className={styles.header}>
            <Text className={styles.title}>
              {userInfo?.first_name !== ""
                ? `${userInfo?.first_name} ${userInfo?.last_name}`
                : userInfo?.username}
            </Text>
            {userInfo?.title && (
              <Text className={styles.secondaryTitle}>
                Title: {userInfo?.title}
              </Text>
            )}
            {isClient && (
              <Text className={styles.secondaryTitle}>
                Company: {userInfo?.company?.name}
              </Text>
            )}
          </div>

          <div className={styles.navLinks}>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navigationListItemActive} ${styles.navigationListItem}`
                  : styles.navigationListItem
              }
              onClick={() => setOpen(false)}
            >
              <Text className={styles.navLinkTitle}>Upcoming Visits</Text>
            </NavLink>
            <NavLink
              to="/pending-visits"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navigationListItemActive} ${styles.navigationListItem}`
                  : styles.navigationListItem
              }
              onClick={() => setOpen(false)}
            >
              <Text className={styles.navLinkTitle}>Pending Visits</Text>
            </NavLink>
            <NavLink
              to="/visits-history"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navigationListItemActive} ${styles.navigationListItem}`
                  : styles.navigationListItem
              }
              onClick={() => setOpen(false)}
            >
              <Text className={styles.navLinkTitle}>Visits History</Text>
            </NavLink>
            <NavLink
              to="/my-profile"
              className={({ isActive }) =>
                isActive
                  ? `${styles.navigationListItemActive} ${styles.navigationListItem}`
                  : styles.navigationListItem
              }
              onClick={() => setOpen(false)}
            >
              <Text className={styles.navLinkTitle}>My Profile</Text>
            </NavLink>
            {userInfo?.role === "employee" &&
              !userInfo.has_checked_in_today && (
                <Button
                  onClick={handleCheckIn}
                  isLoading={isSendLocationLoading}
                >
                  Check In
                </Button>
              )}
            {userInfo?.role === "employee" &&
              userInfo.has_checked_in_today &&
              !userInfo.has_checked_out_today && (
                <Button
                  onClick={handleCheckOut}
                  isLoading={isSendLocationLoading}
                >
                  Check Out
                </Button>
              )}
          </div>
          <div className={styles.logoutContainer}>
            <Button
              onClick={() => logoutHandler()}
              btnClassName={styles.logout}
              prefix={<Icon name="logout" size={24} color="danger" />}
            >
              Logout
            </Button>
          </div>
        </Drawer>
      </div>
    </div>
  );
}
